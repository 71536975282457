.spartan-dot-icon .spartan-dot {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  background-color: #08f29c;
  margin-right: 5px;
}

.spartan-vehicle-icon .spartan-vehicle {
  width: 5px;
  height: 10px;
  border: 1px solid #08f29c;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-size: 12px;
}

.spartan-sensor-icon .spartan-sensor {
  width: 10px;
  height: 10px;
  border: 1px solid blue;

  margin-right: 5px;
}